<template>
  <div class="home-slider-container d-none">
    <div class="slider-wrapper">
      <swiper :options="swiperOption">
        <!-- slide -->
        <swiper-slide class="slider-slide">
          <app-image
            :lazy-srcset="
              `${photos[0][1]} 200w,` +
                `${photos[0][2]} 600w,` +
                `${photos[0][3]} 883w,` +
                `${photos[0][4]} 1126w,` +
                `${photos[0][5]} 1373w,` +
                `${photos[0][6]} 1400w,`
            "
            :src="require('@images/slide/04_mzste8_c_scale,w_200-blur.jpg')"
            :lazy-src="require('@images/slide/04_mzste8_c_scale,w_1400.jpg')"
            alt="revealing-promotion"
          />
        </swiper-slide>
        <!-- slide -->
        <swiper-slide class="slider-slide">
          <app-image
            :lazy-srcset="
              `${photos[1][1]} 200w,` +
                `${photos[1][2]} 600w,` +
                `${photos[1][3]} 883w,` +
                `${photos[1][4]} 1126w,` +
                `${photos[1][5]} 1373w,` +
                `${photos[1][6]} 1400w,`
            "
            :src="require('@images/slide/1,w_200.jpg')"
            :lazy-src="require('@images/slide/1,w_1400.jpg')"
            alt="revealing-promotion"
          />
        </swiper-slide>
        <!-- slide -->
        <!-- slide -->
        <swiper-slide class="slider-slide">
          <app-image
            :lazy-srcset="
              `${photos[2][1]} 200w,` +
                `${photos[2][2]} 600w,` +
                `${photos[2][3]} 883w,` +
                `${photos[2][4]} 1126w,` +
                `${photos[2][5]} 1373w,` +
                `${photos[2][6]} 1400w,`
            "
            :src="require('@images/slide/2,w_200.jpg')"
            :lazy-src="require('@images/slide/2,w_1400.jpg')"
            alt="revealing-promotion"
          />
        </swiper-slide>
        <!-- slide -->
        <!-- slide -->
        <swiper-slide class="slider-slide">
          <app-image
            :lazy-srcset="
              `${photos[3][1]} 200w,` +
                `${photos[3][2]} 600w,` +
                `${photos[3][3]} 883w,` +
                `${photos[3][4]} 1126w,` +
                `${photos[3][5]} 1373w,` +
                `${photos[3][6]} 1400w,`
            "
            :src="require('@images/slide/3,w_200.jpg')"
            :lazy-src="require('@images/slide/3,w_1400.jpg')"
            alt="revealing-promotion"
          />
        </swiper-slide>
        <!-- slide -->
        <!-- slide -->
        <swiper-slide class="slider-slide">
          <app-image
            :lazy-srcset="
              `${photos[4][1]} 200w,` +
                `${photos[4][2]} 600w,` +
                `${photos[4][3]} 883w,` +
                `${photos[4][4]} 1126w,` +
                `${photos[4][5]} 1373w,` +
                `${photos[4][6]} 1400w,`
            "
            :src="require('@images/slide/4,w_200.jpg')"
            :lazy-src="require('@images/slide/4,w_1400.jpg')"
            alt="revealing-promotion"
          />
        </swiper-slide>
        <!-- slide -->
        <div
          slot="button-prev"
          class="swiper-button-prev swiper-button-white"
        ></div>
        <div
          slot="button-next"
          class="swiper-button-next swiper-button-white"
        ></div>
        <div
          slot="pagination"
          class="swiper-pagination swiper-button-white"
        ></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import AppImage from "../appimage/AppImage";
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
export default {
  name: "HomeSlider",
  components: {
    swiper,
    swiperSlide,
    AppImage
  },
  data() {
    return {
      photos: [
        {
          1: require("@images/slide/04_mzste8_c_scale,w_200.jpg"),
          2: require("@images/slide/04_mzste8_c_scale,w_600.jpg"),
          3: require("@images/slide/04_mzste8_c_scale,w_883.jpg"),
          4: require("@images/slide/04_mzste8_c_scale,w_1126.jpg"),
          5: require("@images/slide/04_mzste8_c_scale,w_1373.jpg"),
          6: require("@images/slide/04_mzste8_c_scale,w_1400.jpg")
        },
        {
          1: require("@images/slide/1,w_200.jpg"),
          2: require("@images/slide/1,w_600.jpg"),
          3: require("@images/slide/1,w_883.jpg"),
          4: require("@images/slide/1,w_1126.jpg"),
          5: require("@images/slide/1,w_1373.jpg"),
          6: require("@images/slide/1,w_1400.jpg")
        },
        {
          1: require("@images/slide/2,w_200.jpg"),
          2: require("@images/slide/2,w_600.jpg"),
          3: require("@images/slide/2,w_883.jpg"),
          4: require("@images/slide/2,w_1126.jpg"),
          5: require("@images/slide/2,w_1373.jpg"),
          6: require("@images/slide/2,w_1400.jpg")
        },
        {
          1: require("@images/slide/3,w_200.jpg"),
          2: require("@images/slide/3,w_600.jpg"),
          3: require("@images/slide/3,w_883.jpg"),
          4: require("@images/slide/3,w_1126.jpg"),
          5: require("@images/slide/3,w_1373.jpg"),
          6: require("@images/slide/3,w_1400.jpg")
        },
        {
          1: require("@images/slide/4,w_200.jpg"),
          2: require("@images/slide/4,w_600.jpg"),
          3: require("@images/slide/4,w_883.jpg"),
          4: require("@images/slide/4,w_1126.jpg"),
          5: require("@images/slide/4,w_1373.jpg"),
          6: require("@images/slide/4,w_1400.jpg")
        }
      ],
      swiperOption: {
        dragable: true,
        autoplay: {
          delay: 5000
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true
        }
      }
    };
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
@import "@scss/home/home_slider";
</style>
