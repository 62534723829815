<template>
  <div class="service-recomm mt-5 w-100 pb-5">
    <!-- row1 -->
    <div class="row m-0">
      <div
        v-for="service in recomm"
        :key="service.name_en"
        ref="box"
        class="col-6 col-md-4 col-lg-3 box"
      >
        <router-link
          :to="{ name: 'ServiceSub', params: { subname: service.link } }"
        >
          <div class="image-box">
            <app-image
              :lazy-srcset="
                `${service.image[0]} 200w,` +
                  `${service.image[1]} 934w,` +
                  `${service.image[2]} 1400w,`
              "
              :src="service.image[0]"
              :lazy-src="service.image[2]"
              :alt="`revealing-clinic-${service.name_en}-service`"
            />
          </div>
          <div class="content-box">
            <div>
              <div>{{ service.name_en }}</div>
              <div class="d-none d-sm-block pt-1">{{ service.name_th }}</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AppImage from "../appimage/AppImage";
export default {
  name: "ServiceRecomm",
  components: {
    AppImage
  },
  data() {
    return {
      box_height: 0,
      recomm: [
        {
          name_en: "Cosmetic Surgery",
          name_th: "ศัลยกรรม (ดูดไขมัน)",
          link: "surgery",
          image: [
            require("@images/service/surgery_z6dmxf_c_scale,w_200-blur.jpg"),
            require("@images/service/surgery_z6dmxf_c_scale,w_200.jpg"),
            require("@images/service/surgery_z6dmxf_c_scale,w_934.jpg"),
            require("@images/service/surgery_z6dmxf_c_scale,w_1400.jpg")
          ]
        },
        {
          name_en: "Melt Me",
          name_th: "กำจัดไขมัน ลดเซลลูไลท์",
          link: `fat`,
          image: [
            require("@images/service/meltme_blur.jpg"),
            require("@images/service/meltme_200.jpg"),
            require("@images/service/meltme_763.jpg"),
            require("@images/service/meltme_1400.jpg")
          ]
        },
        {
          name_en: "Wrinkle",
          name_th: "รักษาริ้วรอย",
          link: "wrinkle",
          image: [
            require("@images/service/wrinkle_l32wsk_c_scale,w_200-blur.jpg"),
            require("@images/service/wrinkle_l32wsk_c_scale,w_200.jpg"),
            require("@images/service/wrinkle_l32wsk_c_scale,w_928.jpg"),
            require("@images/service/wrinkle_l32wsk_c_scale,w_1400.jpg")
          ]
        },
        {
          name_en: "Fat Lifting",
          name_th: "สลายไขมัน ยกกระชับ",
          link: "fat",
          image: [
            require("@images/service/fat-lifting_hwmf10_c_scale,w_200-blur.jpg"),
            require("@images/service/fat-lifting_hwmf10_c_scale,w_200.jpg"),
            require("@images/service/fat-lifting_hwmf10_c_scale,w_952.jpg"),
            require("@images/service/fat-lifting_hwmf10_c_scale,w_1400.jpg")
          ]
        },
        {
          name_en: "SKIN",
          name_th: "บำรุงผิว และ ชะลอวัย",
          image: [
            require("@images/service/skin_v5bo4q_c_scale,w_200-blur.jpg"),
            require("@images/service/skin_v5bo4q_c_scale,w_200.jpg"),
            require("@images/service/skin_v5bo4q_c_scale,w_928.jpg"),
            require("@images/service/skin_v5bo4q_c_scale,w_1400.jpg")
          ],
          link: "skin"
        },
        {
          name_en: "Acne Scar",
          name_th: "รักษาหลุมสิว",
          link: "scar",
          image: [
            require("@images/service/acne_ypgnbg_c_scale,w_200-blur.jpg"),
            require("@images/service/acne_ypgnbg_c_scale,w_200.jpg"),
            require("@images/service/acne_ypgnbg_c_scale,w_952.jpg"),
            require("@images/service/acne_ypgnbg_c_scale,w_1400.jpg")
          ]
        },
        {
          name_en: "Hifu",
          name_th: "ไฮฟู่",
          link: "tech",
          image: [
            require("@images/service/hifu_c4ambw_c_scale,w_200-blur.jpg"),
            require("@images/service/hifu_c4ambw_c_scale,w_200.jpg"),
            require("@images/service/hifu_c4ambw_c_scale,w_925.jpg"),
            require("@images/service/hifu_c4ambw_c_scale,w_1400.jpg")
          ]
        }
      ]
    };
  },
  mounted() {
    this.box_height = this.$refs.box[0].clientWidth + "px";
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/color";
@import "@scss/media";

$margin: 20px;
$aspect-width: 4;
$aspect-height: 3;
$constant: ($margin * $aspect-width) / ($aspect-height * 3);

.service-recomm {
  .box {
    padding: 7px;
    padding-bottom: 0;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 0.5;
    }
    @include small {
      padding-bottom: 15px;
    }
    a {
      display: block;
      height: 100%;
    }
  }

  .image-box {
    width: 100%;
    height: calc((2 / 3 * (100% - #{$margin})) + #{$constant}) !important;
    box-shadow: $gray2 0 0 5px 1px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .content-box {
    display: flex;
    background: $white2;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
    box-shadow: $gray2 0 0 5px 1px;

    @include small {
      padding: 15px;
      font-size: 15px;
    }

    > div > div:first-of-type {
      font-weight: bold;
      border-bottom: 2px solid $pink1;
    }

    > div > div:last-of-type {
      font-size: 15px;
    }
  }

  // img {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   transition: padding 0.5s ease-out;
  // }

  a {
    text-decoration: none;
    color: $dark1;
  }
}
</style>
