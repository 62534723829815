<template>
  <div class="home-about-container pt-3">
    <div class="title">
      ABOUT US
    </div>
    <div class="sub">
      เกี่ยวกับเรา
    </div>
    <div class="content box-wrapper">
      Revealing Clinic คือคลินิกเวชกรรมรีวิวลิ่ง ศัลยกรรมความงาม
      โดยพญ.แพทย์​หญิงธนพร วรศักดิ์นุกูล เป็นผู้ดูแลเรื่องผิวหนังโดยตรง
      ทำให้สามารถดูแลปัญหาผิวพรรณ ด้วยความเข้าใจ และพร้อมแก้ไขปัญหา
      ด้วยประสบการณ์ ออกแบบ ปรับรูปหน้า เป็นศาสตร์เฉพาะทาง ที่ผ่านการอบรม
      จากประเทศเกาหลี ทำให้ คนไข้ Revealing Clinic จะมั่นใจ ถึงคุณภาพของการดูแล
      รักษาด้วยความใส่ใจ ประกอบกับคุณภาพของยา ผลิตภัณฑ์ ต้องดีที่สุด
      สำหรับผู้ใช้บริการ เพราะด้วยนโยบายของคุณหมอ ที่จะต้องดูแล เอาใจใส่
      ด้วยตัวเองอีกครั้ง โดยสิ่งสำคัญที่สุด ก็คือยา รวมถึงผลิตภัณฑ์ทุกตัว
      ต้องดีและปลอดภัย เท่านั้น
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAbout"
};
</script>

<style lang="scss" scoped>
@import "@scss/default";
@import "@scss/color";

.home-about-container {
  margin-top: 50px;
  margin-bottom: 10vh;
}
.title {
  text-align: center;
  font-size: 30px;
}

.sub {
  text-align: center;
  font-size: 15px;
  color: $pink1;
}

.content {
  padding: 0 10vw;
  margin-top: 20px;
  text-indent: 20px;
  @include medium {
    padding: 0 0px;
    font-size: 15px;
  }
}
</style>
