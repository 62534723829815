<template>
  <div class="home-recom-container">
    <hr />
    <div class="title  box-wrapper">
      RECOMMENDED
    </div>
    <div class="sub mb-4">
      บริการที่หลากหลายสำหรับคุณ
    </div>
    <ServiceRecomm class="" />
  </div>
</template>

<script>
import ServiceRecomm from "../service/ServiceRecomm";
export default {
  name: "HomeRecom",
  components: {
    ServiceRecomm
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/default";
@import "@scss/color";

.home-recom-container {
  margin-top: 10vh;
  text-align: center;
}

.title {
  margin-top: 10vh;
  font-size: 40px;
  color: $pink1;
}

hr {
  border: none;
  display: block;
  width: 100%;
  height: 3px;
  background: $pink1;
}

.box-wrapper {
  margin-top: 0 !important;
}
</style>
