<template>
  <div class="home-expert-container pt-3">
    <div class="box-wrapper expert-wrapper ">
      <div class="expert-content">
        <div class="show pb-5">
          <div class="expert-bg ">
            <div class="row m-0">
              <div class="col video">
                <!-- <iframe
                  src="https://drive.google.com/file/d/1gopO96WH1olQDzYmeTOwCzN1-hrnMyHL/preview"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe> -->
                <div class="content">
                  <youtube
                    video-id="Wg03USvUqmU"
                    class="w-100 h-100 p-2"
                    player-width="100%"
                    player-height="100%"
                  ></youtube>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeExpert"
};
</script>

<style lang="scss" scoped>
@import "@scss/aspect-ratio";
@import "@scss/default";
@import "@scss/color";

.video {
  @include aspect-ratio(16, 9);
}

iframe {
  height: 50vh;
  width: 100%;
  max-width: 70vw;
  padding: 30px;

  img {
    object-fit: cover;
  }
  @include medium {
    height: 30vh;
  }

  @include small {
    height: 50vw;
  }
}

.expert-wrapper {
  position: relative;

  .expert-bg {
    background: $pink3;
    @include medium {
      padding: 15px;
    }
    @include small {
      padding: 0;
      .col {
        padding: 0;
      }
    }
  }
  .expert-content {
    text-align: center;
    position: relative;
    z-index: 1;

    .sub {
      color: $pink1;
    }

    .title {
      font-size: 40px;
    }

    .show {
      margin-top: 30px;
      width: 100%;

      div {
        font-size: 20px;
      }
      img {
        width: 100%;
        padding: 15px;
      }
    }
  }
}
</style>
