<template>
  <div class="home-container">
    <HomeSlider class="mb-3 " />
    <HomeAbout class="mb-3" />
    <HomeExpert class="mb-3" />
    <HomeRecom class="mb-3" />
  </div>
</template>

<script>
import HomeSlider from "./HomeSlider";
import HomeAbout from "./HomeAbout";
import HomeExpert from "./HomeExpert";
import HomeRecom from "./HomeRecom";
export default {
  name: "Home",
  components: {
    HomeSlider,
    HomeAbout,
    HomeExpert,
    HomeRecom
  },
  data() {
    return {
      data: require("@images/logo.png")
    };
  },
  metaInfo: {
    title:
      "คลินิกเวชกรรมความงาม และศัลยกรรมความงาม โดยคุณหมอเรื่องผิวหนังโดยตรง",
    meta: [
      {
        name: "og:title",
        content:
          "คลินิกเวชกรรมความงาม และศัลยกรรมความงาม โดยคุณหมอเรื่องผิวหนังโดยตรง | Revealing Clinic"
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/home/home";
@import "@scss/default";
</style>
